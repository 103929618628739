/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight, faEdit, faFilePdf, faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {
  updateNewUsersRepDetailsByTypeForPlant,
  getNewUserDetailsRepById,
  handleNewUserRepApprovalpreparedByPlantIC,
  handleNewUserRepRejectionpreparedByPlantIC,
  getAllHpcCodesBySearch,
  getSignatureData,
  deleteSignatureById,
  postSignatureDataInPlantSide,
} from '../../store/modules/newUser/actions';
import {
  selectIsLoading,
  selectSingleUserData,
  selectIsLoadingApprovalPlant,
  selectIsLoadingRejectionPlant,
  selectSearchedData,
  selectSignatureArray,
  selectIpAddress,
} from '../../store/modules/newUser/selectors';
import EditNewUserModal from '../../components/EditNewUserModal';
import EditLocationModal from '../../components/EditNewUserLocationModal';
import EditBankDetailsModal from '../../components/EditBankDetailsModal';
import EditSearchModalComponent from '../../components/EditSearchModalComponent';
import DigitalSignatureModal from '../../components/DigitalSingatureModal';
import SignaturesModal from '../../components/SignaturesModal';
import EditGpaModal from '../../components/EditGpaModal';
import LoadingModal from '../../components/LoadingModal';
import ModalForImageZoom from '../../components/ModalForImageZoom';
import './PendinHPCregistrationPlantSide.scss';

const ViewRegistaration = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectSingleUserData(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  const searchedData = useSelector((state) => selectSearchedData(state));
  const isLoadingFinish = useSelector((state) => selectIsLoadingApprovalPlant(state));
  const isLoadingReject = useSelector((state) => selectIsLoadingRejectionPlant(state));
  const ipAddress = useSelector((state) => selectIpAddress(state));
  const signatureArray = useSelector((state) => selectSignatureArray(state));
  const role = localStorage.getItem('role');
  const { id } = useParams();
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showSearchModal, setshowSearchModal] = useState(false);
  const [showBankDetailsModal, setshowBankDetailsModal] = useState(false);
  const [showDigitalSignatureModal, setshowDigitalSignatureModal] = useState(
    false,
  );
  const [showSignatureModal, setshowSignatureModal] = useState(false);
  const [showModalForGPAUsers, setshowModalForGPAUsers] = useState(false);
  const [maskAadhar, setmaskAadhar] = useState(false);
  const [showImageModal, setshowImageModal] = useState(false);
  const [selectedImageValues, setselectedImageValues] = useState(null);
  const [catl, setCattle] = useState(null);
  const checkIfDataIsNotNull = data?.village && data?.pinCode && data?.mandal && data?.hNoDno;
  const [storeValuesForLocation, setStoreValuesForLocation] = useState({
    village: null,
    pinCode: null,
    mandal: null,
    hNoDno: null,
  });
  const [ip, setIp] = useState('IP Address not available');
  useEffect(() => {
    if (checkIfDataIsNotNull) {
      setStoreValuesForLocation(data);
    }
  }, [data, checkIfDataIsNotNull]);
  useEffect(() => {
    const dataTobeSent = {
      id,
      type: 'plant',
    };
    dispatch(getNewUserDetailsRepById(dataTobeSent));
  }, [dispatch]);

  useEffect(() => {
    const ctx = chartRef.current;
    if (!ctx) {
      return;
    }
    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          '23 Jun',
          '24 Jun',
          '25 Jun',
          '26 Jun',
          '27 Jun',
          '28 Jun',
          '29 Jun',
        ],
        // labels: milkSlipsStats && milkSlipsStats.length
        //   ? milkSlipsStats.map((milkSlipStats) => moment(milkSlipStats.date).format('DD MMM'))
        //   : null,
        datasets: [
          {
            label: '',
            data: [12, 19, 3, 5, 2, 3, 5.2],
            // data: milkSlipsStats && milkSlipsStats.length
            //   ? milkSlipsStats.map((milkSlipStats) => Number(milkSlipStats.totalQuantity))
            //   : null,
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 2,
            tension: 0.2,
          },
        ],
      },
      plugins: [
        {
          id: 'canvas_background_color',
          beforeDraw: (chart) => {
            const canvas = chart.canvas.getConcontent__text('2d');
            canvas.save();
            canvas.globalCompositeOperation = 'destination-over';
            canvas.fillStyle = '#175fad';
            canvas.fillRect(0, 0, chart.width, chart.height);
            canvas.restore();
          },
        },
      ],
      options: {
        layout: {
          padding: 20,
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
          y: {
            title: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      chartInstance.destroy();
    };
  });

  const downloadImage = (e) => {
    fetch(e.link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data?.farmerName} - ${e.type}.png`); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleOnImagepressed = (img) => {
    setshowImageModal(!showImageModal);
    setselectedImageValues(img);
  };

  const handleIpAddress = () => {
    ipAddress?.then((res) => {
      setIp(res ? res?.ip : 'IP Not fetched');
    });
  };
  useEffect(() => {
    handleIpAddress();
  }, []);
  const handleOnSignatureArrayDataCalled = () => {
    setshowSignatureModal(!showSignatureModal);
    const values = {
      type: 'plant',
    };
    dispatch(getSignatureData(values));
  };

  const handleOnDigitalSignatureModalCalled = () => {
    setshowDigitalSignatureModal(!showDigitalSignatureModal);
  };
  const handleOnValueSelectedFromSignatureModal = (val) => {
    const approvedData = {
      id,
      status: 'approved',
      ipAddress: ip,
      signatureUrl: val?.signatureUrl,
    };
    dispatch(handleNewUserRepApprovalpreparedByPlantIC(approvedData));
  };
  const handleOnDeleteSignatureCalled = (value) => {
    const valuesToBepassed = {
      id: value?.id,
      type: 'plant',
    };
    dispatch(deleteSignatureById(valuesToBepassed));
  };

  const handleOnSignatureFetchedFromCanvasModal = (val) => {
    const values = {
      signature: val,
    };
    if (val !== null) {
      dispatch(postSignatureDataInPlantSide(values));
      handleOnSignatureArrayDataCalled();
    }
  };
  const reject = () => {
    Swal.fire({
      title: 'Enter your reason for rejection',
      input: 'textarea',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          const rejectData = {};
          rejectData.id = id;
          rejectData.status = 'rejected';
          rejectData.comment = result?.value;
          dispatch(handleNewUserRepRejectionpreparedByPlantIC(rejectData));
        }
        Swal.fire({
          title: 'Update!',
          text: 'You have successfully rejected',
          icon: 'success',
          timer: 2000,
        });
      }
    });
  };

  const approve = () => {
    if (
      storeValuesForLocation.village !== null
      && storeValuesForLocation?.hNoDno !== null
      && storeValuesForLocation.mandal !== null
      && storeValuesForLocation?.pinCode !== null
    ) {
      handleOnSignatureArrayDataCalled();
      // Swal.fire({
      //   title: 'Alert!',
      //   content__text: 'Have you filled all the details?',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtoncontent__text: 'Yes',
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     dispatch(handleNewUserRepApprovalpreparedByPlantIC(approvedData));
      //     Swal.fire(
      //       'Approved!',
      //       `you have succesfully approved ${data?.farmerName}`,
      //       'success',
      //     );
      //   }
      // });
    } else {
      Swal.fire('Alert', 'Please fill all the details', 'warning');
    }
  };

  const toggleEditUserModal = () => {
    setShowEditUserModal(!showEditUserModal);
  };

  const toggleEditLocationModal = () => {
    setShowEditLocationModal(!showEditLocationModal);
  };

  const toggleBankDetailsModal = () => {
    setshowBankDetailsModal(!showBankDetailsModal);
  };

  const toggleGpaModal = () => {
    setshowModalForGPAUsers(!showModalForGPAUsers);
  };

  const toggleSearchModal = () => {
    const valueTobePassed = {
      type: 'plant',
    };
    dispatch(getAllHpcCodesBySearch(valueTobePassed));
    setshowSearchModal(!showSearchModal);
  };
  const submitHPCCode = (e) => {
    const datatobeSearched = {
      type: 'plant',
      hpcCode: e?.hpcCode,
    };
    if (e?.hpcCode !== '') {
      dispatch(getAllHpcCodesBySearch(datatobeSearched));
    }
  };
  const onHpcCodeSelected = (e) => {
    const dataNeedsTobeUpdated = {
      hpcCode: e?.hpcCode?.toString(),
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByTypeForPlant(dataNeedsTobeUpdated));
    setshowSearchModal(false);
    // const dataTobeSent = {
    //   id,
    //   type: 'plant',
    // };
    // dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const submitUser = (values) => {
    const userDetailsThatNeedToBeUpdated = {
      farmerName: values?.farmerName,
      gender: values?.gender,
      dob: values?.dob,
      aadharNumber: values?.aadharNumber,
      phone: values?.phone,
      id,
      status: 'pending',
    };
    dispatch(
      updateNewUsersRepDetailsByTypeForPlant(userDetailsThatNeedToBeUpdated),
    );
    // const dataTobeSent = {
    //   id,
    //   type: 'plant',
    // };
    // dispatch(getNewUserDetailsRepById(dataTobeSent));
  };

  const submitLocation = (values) => {
    const repUpdateData = {
      noOfBuffalo: values?.noOfBuffalo ? values?.noOfBuffalo : null,
      buffaloMilkQuantity: values?.buffaloMilkQuantity
        ? values?.buffaloMilkQuantity
        : null,
      noOfCows: values?.noOfCows ? values?.noOfCows : null,
      cowMilkquantity: values?.cowMilkquantity ? values?.cowMilkquantity : null,
      // farmerCode: values?.farmerCode ? values?.farmerCode : null,
      // vendorCode: values?.vendorCode ? values?.vendorCode : null,
      village: values?.village ? values?.village : null,
      hNoDno: values?.hNoDno ? values?.hNoDno : null,
      mandal: values?.mandal ? values?.mandal : null,
      district: values?.district ? values?.district : null,
      pinCode: values?.pinCode ? values?.pinCode : null,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByTypeForPlant(repUpdateData));
    // const dataTobeSent = {
    //   id,
    //   type: 'plant',
    // };
    // dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const submitBankDetails = (values) => {
    const repUpdateData = {
      bankName: values?.bankName,
      branchName: values?.branchName,
      ifscCode: values?.ifscCode,
      accountNumber: values?.accountNumber,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByTypeForPlant(repUpdateData));
    // const dataTobeSent = {
    //   id,
    //   type: 'plant',
    // };
    // dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const submitGpaDetails = (values) => {
    const repUpdateData = {
      nomineeIdentity: values?.nomineeIdentity,
      nomineeName: values?.nomineeName,
      enrolmentId: values?.enrolmentId,
      id,
      status: 'pending',
    };
    dispatch(updateNewUsersRepDetailsByTypeForPlant(repUpdateData));
    // const dataTobeSent = {
    //   id,
    //   type: 'plant',
    // };
    // dispatch(getNewUserDetailsRepById(dataTobeSent));
  };
  const aadharNumberTobeShown = data?.aadharNumber?.replace(
    /\d(?=\d{4})/g,
    '*',
  );
  const toggleMaskingOfAadar = () => {
    setmaskAadhar(!maskAadhar);
  };
  const handlePdfDownload = (val) => {
    fetch(val, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data?.farmerName} - E-KYC-REPORT.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleToggleFullScreen = () => {
    Swal.fire({
      icon: 'info',
      title: 'Do you know?',
      text: 'By default the zoom for the pdf is set to 25% you can change this in the pdf viewer window.',
      showCancelButton: true,
      confirmButtonColor: '#008000',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Open PDF viewer',
      cancelButtonText: 'Close',
      focusConfirm: false,
      toast: true,
      footer: `<a href="${data?.idvReport}">View Report</a>`,
    }).then((response) => {
      if (response?.isConfirmed) {
        const iframe = document.getElementById('pdfViewer');
        if (iframe) {
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen();
          } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen();
          } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen();
          }
        }
      }
    });
  };

  return (
    <>
      {!isLoading && data ? (
        <div className="view-registration">
          {data?.preparedByPlantIC === 'pending' ? (
            <div className="row center">
              <div className="col-12 col-md-12">
                <div className="buttons">
                  <button
                    type="button"
                    className="button danger"
                    onClick={reject}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    onClick={approve}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row center">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="status-card">
                <div className="left">
                  1.Prepared By Plant IC :
                  {' '}
                  {data.preparedByPlantIC}
                </div>
                <div className="right">
                  2.Status at RI F&A :
                  {''}
                  {data?.verifiedByRIFandA}
                </div>
                <div className="left">
                  3.Status at RI P & I :
                  {' '}
                  {data.approvedByRIPandI}
                </div>
                <div className="right">
                  4.Status at EA P&I :
                  {''}
                  {data?.createdByEAPandI}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">User details</h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {data?.isEqualGenerated === true ? null : (
                          <>
                            {data?.preparedByPlantIC === 'pending' ? (
                              <div className="col-12 d-flex end">
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="edit-icon"
                                  onClick={toggleEditUserModal}
                                />
                              </div>
                            ) : null}
                          </>
                        )}

                        <div className="col-12 col-lg-6 d-flex-col">
                          <div className="row right">
                            {data?.aadharCardUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-left">
                                  <h4 className="title">Aadhar Card Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'Aadhar Card',
                                      link: `${data?.aadharCardUrl}?${new Date().getTime()}`,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data.aadharCardUrl
                                          ? `${data.aadharCardUrl}?${new Date().getTime()}`
                                          : null
                                      }
                                      alt="1"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.bankPassbookUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Bank Passbook Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'Bank Passbook',
                                      link: `${data?.bankPassbookUrl}?${new Date().getTime()}`,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data.bankPassbookUrl
                                          ? `${data.bankPassbookUrl}?${new Date().getTime()}`
                                          : null
                                      }
                                      alt="2"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {data?.panCardUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Pan Card Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'Pan Card',
                                      link: `${data.panCardUrl}?${new Date().getTime()}`,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data.panCardUrl ? `${data.panCardUrl}?${new Date().getTime()}` : null
                                      }
                                      alt="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.nomineeUrl ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">Nominee Image</h4>
                                  <div
                                    className="picture"
                                    onClick={() => handleOnImagepressed({
                                      type: 'NOMINEE IMAGE',
                                      link: data.nomineeUrl,
                                      farmerName: data?.farmerName,
                                    })}
                                  >
                                    <img
                                      src={
                                        data.nomineeUrl ? data.nomineeUrl : null
                                      }
                                      alt="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {data?.idvReport !== null && data?.idvReport !== 'false' ? (
                              <div className="col-12 col-md-6">
                                <div className="item p-right">
                                  <h4 className="title">E-KYC Report</h4>
                                  <div
                                    className="pdf-container"
                                  >
                                    <iframe
                                      id="pdfViewer"
                                      src={data?.idvReport}
                                      title="PDF Viewer"
                                      width="100%"
                                      height="500px"
                                    />
                                    <button
                                      type="button"
                                      onClick={handleToggleFullScreen}
                                    >
                                      <FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{ marginRight: 5 }} />
                                      Full Screen
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex-col">
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Name</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data.farmerName}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Phone Number</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data?.phone
                                  ? data?.phone?.replace('+91', '')
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Gender</div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {data?.gender ? data?.gender : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Date of Birth
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="content__content__text">
                                {moment(data?.dob).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">
                                Aadhar Number
                              </div>
                            </div>
                            <div
                              className="col-8"
                              onClick={() => toggleMaskingOfAadar()}
                            >
                              <div className="content__text">
                                {maskAadhar
                                  ? data?.aadharNumber
                                  : aadharNumberTobeShown}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Plant Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.plantCode ? data?.plantCode : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">HPC Code</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.hpcCode ? data?.hpcCode : 'N/A'}
                                {data?.preparedByPlantIC === 'pending' ? (
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="edit-icon"
                                    onClick={toggleSearchModal}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row between">
                            <div className="col-3">
                              <div className="content__title">Role</div>
                            </div>
                            <div className="col-8">
                              <div className="content__text">
                                {data?.mccType
                                  ? data?.mccType === 'hpc_farmer'
                                    ? 'HPC Farmer'
                                    : 'HPC Representative'
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                          {
                             data?.idvReport !== null && data?.idvReport !== 'false'
                               ? (
                                 <div className="row between">
                                   <div className="col-3">
                                     <div className="content__title">E-KYC Report</div>
                                   </div>
                                   <div className="col-8">
                                     <button type="button" className="button success" onClick={() => handlePdfDownload(data?.idvReport)}>
                                       <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 5 }} />
                                       {' '}
                                       Download Report
                                     </button>
                                   </div>
                                 </div>
                               ) : null
                            }
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex">
                      <div className="row w-100 m-h-100">
                        {data?.preparedByPlantIC === 'pending' ? (
                          <div className="col-12 d-flex end">
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="edit-icon"
                              onClick={toggleEditLocationModal}
                            />
                          </div>
                        ) : null}
                        <div className="col-12 col-md-6">
                          <div>
                            {role === 'plant-admin' ? (
                              <>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      No Of Cows
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data.noOfCows ? data?.noOfCows : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Cow Milk Quantity
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.cowMilkquantity
                                        ? data?.cowMilkquantity
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      No of Buffalo
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.noOfBuffalo
                                        ? data?.noOfBuffalo
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row w-100 between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Buffalo Milk Quantity
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.buffaloMilkQuantity
                                        ? data?.buffaloMilkQuantity
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Farmer Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.farmerCode
                                        ? data?.farmerCode
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                                <div className="row between w-100">
                                  <div className="col-4">
                                    <div className="content__title">
                                      Vendor Code
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="content__text">
                                      {data?.vendorCode
                                        ? data?.vendorCode
                                        : 'N/A'}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {role === 'plant-admin' ? (
                          <div className="col-12 col-md-6">
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">
                                  House No/ Door No
                                </div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.hNoDno ? data?.hNoDno : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">District</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.district ? data?.district : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Mandal</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.mandal ? data?.mandal : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row between w-100">
                              <div className="col-4">
                                <div className="content__title">Village</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.village ? data?.village : 'N/A'}
                                </div>
                              </div>
                            </div>
                            <div className="row w-100 between w-100">
                              <div className="col-4">
                                <div className="content__title">Pin Code</div>
                              </div>
                              <div className="col-7">
                                <div className="content__text">
                                  {data?.pinCode ? data?.pinCode : 'N/A'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">
                  Bank details
                  {' '}
                  {data?.isEqualGenerated === true ? null : (
                    <>
                      {data?.preparedByPlantIC === 'pending' ? (
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="edit-icon"
                          onClick={() => {
                            setCattle(data);
                            toggleBankDetailsModal();
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Bank Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.bankName ? data?.bankName : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Branch Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.branchName ? data.branchName : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">IFSC Code</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.ifscCode ? data?.ifscCode : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Account Number</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.accountNumber ? data?.accountNumber : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">
                  GPA Nominee details
                  {data?.preparedByPlantIC === 'pending' ? (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="edit-icon"
                      onClick={() => {
                        toggleGpaModal();
                      }}
                    />
                  ) : null}
                </h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">Nominee Name</div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.nomineeName ? data?.nomineeName : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="row between">
                        <div className="col-5">
                          <div className="content__title">
                            Nominee Relationship
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="content__text">
                            {data?.nomineeIdentity
                              ? data.nomineeIdentity
                              : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="row between">
                        <div className="col-7">
                          <div className="content__title">Enrollment Id</div>
                        </div>
                        <div className="col-4">
                          <div className="content__text">
                            {data?.enrolmentId ? data?.enrolmentId : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex-col">
              <div className="item">
                <h4 className="title">
                  Digital Signature
                </h4>
                <div className="content">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="col-1 col-md-6 d-flex-col justify-content-center">
                        {data?.planticSign ? (
                          <img
                            src={data?.planticSign}
                            alt="signature"
                            className="col-md-6 d-flex"
                          />
                        ) : (
                          <div className="content__text">N/A</div>
                        )}
                        <div className="content__mtitle">Plant Incharge</div>
                        {
                          data?.planticSignDate ? (
                            <div className="content__miniTitle">
                              {`Signed on ${moment(data?.planticSignDate).format('DD MMM, YYYY, hh:mm:a')}`}
                            </div>
                          ) : (
                            <div className="content__miniTitle">
                              Not yet signed
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="col-1 col-md-6 d-flex-col justify-content-center">
                        {data?.rifaSign ? (
                          <img
                            src={data?.rifaSign}
                            alt="signature"
                            className="col-md-6 d-flex"
                          />
                        ) : (
                          <div className="content__text">N/A</div>
                        )}
                        <div className="content__mtitle">
                          Regional Incharge F&A
                        </div>
                        {
                          data?.rifaSignDate ? (
                            <div className="content__miniTitle">
                              {`Signed on ${moment(data?.rifaSignDate).format('DD MMM, YYYY, hh:mm:a')}`}
                            </div>
                          ) : (
                            <div className="content__miniTitle">
                              Not yet signed
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="col-1 col-md-6 d-flex-col justify-content-center">

                        {data?.rhpiSign ? (
                          <img
                            src={data?.rhpiSign}
                            alt="signature"
                            className="col-md-6 d-flex"
                          />
                        ) : (
                          <div className="content__text">N/A</div>
                        )}
                        <div className="content__mtitle">
                          RH P&I
                        </div>
                        {
                          data?.rhpiSignDate ? (
                            <div className="content__miniTitle">
                              {`Signed on ${moment(data?.rhpiSignDate).format('DD MMM, YYYY, hh:mm:a')}`}
                            </div>
                          ) : (
                            <div className="content__miniTitle">
                              Not yet signed
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex-col">
                      <div className="col-1 col-md-6 d-flex-col justify-content-center">
                        {data?.eapiSign ? (
                          <img
                            src={data?.eapiSign}
                            alt="signature"
                            className="col-md-6 d-flex"
                          />
                        ) : (
                          <div className="content__text">N/A</div>
                        )}
                        <div className="content__mtitle">
                          EA P&I
                        </div>
                        {
                          data?.eapiSignDate ? (
                            <div className="content__miniTitle">
                              {`Signed on ${moment(data?.eapiSignDate).format('DD MMM, YYYY, hh:mm:a')}`}
                            </div>
                          ) : (
                            <div className="content__miniTitle">
                              Not yet signed
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showEditUserModal ? (
        <EditNewUserModal
          show={showEditUserModal}
          toggle={toggleEditUserModal}
          onSubmit={submitUser}
          values={data}
        />
      ) : null}
      {showEditLocationModal ? (
        <EditLocationModal
          show={showEditLocationModal}
          toggle={toggleEditLocationModal}
          onSubmit={submitLocation}
          values={data}
        />
      ) : null}
      {showBankDetailsModal ? (
        <EditBankDetailsModal
          show={showBankDetailsModal}
          toggle={toggleBankDetailsModal}
          onSubmit={submitBankDetails}
          values={data}
        />
      ) : null}
      {showModalForGPAUsers ? (
        <EditGpaModal
          show={showModalForGPAUsers}
          toggle={toggleGpaModal}
          onSubmit={submitGpaDetails}
          values={data}
        />
      ) : null}
      {showSearchModal ? (
        <EditSearchModalComponent
          show={showSearchModal}
          toggle={toggleSearchModal}
          onSubmit={(e) => submitHPCCode(e)}
          handleOnItemSelected={(e) => onHpcCodeSelected(e)}
          searchedData={searchedData}
        />
      ) : null}
      {showImageModal ? (
        <ModalForImageZoom
          show={showImageModal}
          toggle={handleOnImagepressed}
          imageUrl={selectedImageValues}
        />
      ) : null}
      {showDigitalSignatureModal ? (
        <DigitalSignatureModal
          show={showDigitalSignatureModal}
          toggle={handleOnDigitalSignatureModalCalled}
          onSubmit={handleOnSignatureFetchedFromCanvasModal}
        />
      ) : null}
      {
        showSignatureModal ? (
          <SignaturesModal
            signatures={signatureArray}
            show={showSignatureModal}
            isLoading={isLoading}
            onSubmit={handleOnValueSelectedFromSignatureModal}
            toggle={handleOnSignatureArrayDataCalled}
            onDelete={handleOnDeleteSignatureCalled}
            showSignatureCanvas={handleOnDigitalSignatureModalCalled}

          />
        ) : null
      }
      <LoadingModal show={isLoading || isLoadingFinish || isLoadingReject} />
    </>
  );
};

export default ViewRegistaration;
